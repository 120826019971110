@import '~styles/base';

.Main {
    background-color: black;
    display: flex;
    flex-direction: row;

    .front {
        width: 100%;
        height: 100%;
        position: absolute;

        display: flex;
        flex-direction: row;

        .overlay {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            text-align: right;
            color: white;
            background-color: rgba(0, 48, 91, 0.65);

            @include breakpoint(gt-mobile) {
                min-width: $title-min-width;
                width: $title-width;

                animation: esca-fade-left 0.5s ease-out 1;
            }

            @include breakpoint(lt-mobile) {
                flex: 1;
                margin: 20px;
                text-align: center;
            }

            .title {
                max-width: 400px;
                font-size: 42px;
                padding: 0px 20px;
                white-space: pre-line;
            }

            .subtitle {
                max-width: 400px;
                padding: 20px;
                font-family: "open sans", sans-serif;
            }
        }
    }

    .background {
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        z-index: -1;

        video {
            opacity: 1;
            object-fit: cover;

            position: fixed;
            top: 0px;
            width: 100%;
            height: 100%;

            @include breakpoint(lt-mobile) {
                display: none;
            }

            /* Add the blur effect */
            filter: blur($main-video-blur);
            -webkit-filter: blur($main-video-blur);
        }
    
        .video-alt {
            top: 0px;
            width: 100%;
            height: 100%;
    
            @include breakpoint(gt-mobile) {
                display: none;
            }

            background-image: url("./video.webp");
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: fixed;
    
            /* Add the blur effect */
            filter: blur($main-video-blur);
            -webkit-filter: blur($main-video-blur);
        }
    }
}
