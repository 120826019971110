

/* Safari 4.0 - 8.0 */
@-webkit-keyframes esca-fade-left {
    from {transform: translateX(-100%);}
    to {transform: translateX(0%);}
}

@keyframes esca-fade-left {
    from {transform: translateX(-100%);}
    to {transform: translateX(0%);}
}
    