@import "~styles/base";


.Slide {
    width: 100%;
    position: relative;
    z-index: 50;
    overflow-y: visible;
    overflow-x: hidden;
    min-height: calc(100vh - 80px);

    .slide-anchor {
        position: absolute;
        top: -80px;
        height: 100%;
    }
}
