@import "~styles/base";

.ServiceBox {
    display: flex;
    flex-direction: row;
    padding: 10px;

    @include breakpoint(gt-mobile) {
        flex: 40%;
    }

    .service-dot {
        display: block;
        margin: 10px;
        max-width: 10px;
        min-width: 10px;
        height: 10px;
        flex: 1 0;

        background-color: rgba(65, 183, 196, 1);
    }

    .service {
        flex: 1;
        padding: 5px;
        font-family: "open sans", sans-serif;

        .service-name {
            font-size: 20px;
            text-transform: uppercase;
            color: rgb(0, 48, 91);
        }

        .service-summary {
            font-size: 18px;
            color: rgb(16, 46, 82);
        }
        
        .service-image {
            // text-align: center;
        }
    }
    img {
        margin: 20px auto;
        width: 85%;
        max-width: 430px;
    }
}