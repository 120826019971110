@import "~styles/base";

.TransitionImage {
    position: relative;
    overflow-x: hidden;
    height: 200px;
    width: 100%;
    z-index: 50;

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 50;

        background-color: rgba($color: #555, $alpha: 0.7);
    }
}
