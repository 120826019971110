@import "~styles/base";


.Footer {
    z-index: 50;
    background-color: white;
    position: relative;
    font-family: "open sans", sans-serif;
    font-size: 14px;
    color: rgb(15, 76, 133);
    padding: 20px;

    .company {
        margin: 10px;
    }
}