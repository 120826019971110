@import "~styles/base";

.Navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    background-color: white;

    height: $navbar-height;
    width: 100%;

    .menu-mobile {
        position: relative;
        height: 50px;
        width: 50px;
        margin: 0 10px;

        svg {
            position: absolute;
            top: 0;
            height: 30px;
            width: 30px;
            padding: 8px;
            border: 1px solid;
            border-radius: 5px;
        }

        .bars {
            display: block;
        }
        
        .times {
            display: none;
        }

        @include breakpoint(gt-mobile) {
            display: none;
        }
    }

    .menu {
        margin: 0 20px;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        @include breakpoint(lt-mobile) {
            // display: none;
            display: flex;
            flex-direction: column;
            position: fixed;
            z-index: 999;
            height: 100%;
            width: 60%;
            left: -100%;
            top: 0;
            margin: 0;
            padding-top: 100px;

            white-space: pre-wrap;
            background-color: white;
            -webkit-box-shadow: 3px 0px 25px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 3px 0px 25px 0px rgba(0,0,0,0.75);
            box-shadow: 3px 0px 25px 0px rgba(0,0,0,0.75);

            transition: left 0.5s;
        }

        .menu-item {
            font-size: 13px;
            text-transform: uppercase;

            @include breakpoint(lt-mobile) {
                font-size: 20px;
                padding: 5px;
                width: 60%;
            }

            &.active {
                a {
                    font-weight: bold;
                }
            }
        }

        > * {
            margin: 0px 10px;
        }
    }

    a {
        color: rgb(15, 76, 133);
        font-weight: 400;
        text-decoration: none;

        &.title {
            @include breakpoint(lt-mobile) {
                flex: 1;
            }
            font-family: "noticia text", serif;
            font-size: 22px;
            margin: 0 10px;

            color: rgb(0, 48, 91);
        }

        &.logo {
            margin: 0 10px;
            height: 80px;
            width: 80px;

            @include breakpoint(lt-mobile) {
                height: 60px;
                width: 60px;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    &.show-menu {

        .menu-mobile {
            .bars {
                display: none;
            }
            
            .times {
                display: block;
            }
        }

        .menu {
            left: 0;
        }
    }
}

/* The animation code */
@keyframes showMenu {
    0% {
        left: -60%;
    }
}
