@import "~styles/base";


.ScrollUp {
    width: 100%;
    position: relative;

    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #00305B;
    color: white;

    .scroll-up-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;

        font-family: "open sans", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;

        svg {
            height: 30px;
            width: 30px;
        }

        span {
            color: white;
            margin: 2px;
        }
    }


}
