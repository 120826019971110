@import "~styles/base";

.Parallox {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;

    .background {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 40;
        top: 0;

        .background-image {
            width: 100%;
            height: 100%;
            top: 0;

            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: fixed;
        }
    }
}
