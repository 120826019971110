

.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .profile-image {
        height: 220px;
        width: 200px;
    }

    .box-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 200px;

        font-family: "open sans", sans-serif;
        color: rgb(0, 48, 91);

        > span {
            margin: 5px 20px; 
        }
    }

    .contact-info {
        margin-top: 20px;
        font-size: 20px;
    }

    .splitter {
        width: 200px;
        height: 1px;
        margin: 20px 0;
        background-color: #A6CCEC;
    }

    .extra-info {
        margin-bottom: 20px;
        font-size: 16px;

        // .email {
        //     text-decoration: none;
        // }
    }
}