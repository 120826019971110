

// Navbar properties
$navbar-height: 80px;

// Main slide
$main-video-blur: 4px;

// Main title width
$title-width: 40%;
$title-min-width: 350px;
