@import "~styles/base";

.People {
    flex: 1;
    background-color: #EAF4F6;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        color: #00305B;
        text-transform: uppercase;
        margin-top: 30px;
        font-size: 42px;
    }

    .human-resources {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @include breakpoint(lt-mobile) {
            flex-direction: column;
            align-items: center;
        }

        > * {
            width: 33%;
        }
    }
}
