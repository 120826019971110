

.Values {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00305B;
    font-family: open sans,sans-serif;

    .container {
        margin: 20px 40px;
        max-width: 800px;
        color: white;

        .title {
            font-size: 25px;
            font-weight: bold;
            text-transform: uppercase;
        }

        .content {
            font-size: 18px;
            text-align: justify;
        }
    }


}
