@import "~styles/base";

.ContactForm {
  display: flex;

  @include breakpoint(lt-mobile) {
    flex-direction: column;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

    background-color: rgba(0, 48, 91, 0.8);
    font-size: 42px;
    color: rgb(0, 48, 91);

    @include breakpoint(gt-mobile) {
      min-width: $title-min-width;
      width: $title-width;
      align-items: flex-end;
    }

    @include breakpoint(lt-mobile) {
      align-items: center;
      padding: 0 10px;
    }

    >div {
      display: flex;
      flex-direction: column;
      max-width: 400px;

      width: 100%;
      color: white;
      text-align: right;

      @include breakpoint(gt-mobile) {
        align-items: flex-end;

        >* {
          margin-right: 40px;
        }
      }

      @include breakpoint(lt-mobile) {
        align-items: center;
        text-align: center;

        padding: 0 15px;
        padding-bottom: 40px;
      }

      .title {
        margin-top: 50px;
        font-weight: bold;
      }
    }
  }

  .form-container {
    flex: 1;
    background-color: rgba(15, 76, 133, 0.8);

    padding: 90px 40px;

    display: flex;
    flex-direction: column;

    font-family: "open sans", sans-serif;
    color: white;

    @include breakpoint(lt-mobile) {
      padding: 20px 30px 90px 30px;
      flex-direction: column;
    }

    .form-title {
      text-transform: uppercase;
      font-size: 20px;
    }

    .highlight {
      font-weight: 700;
    }

    .form-content {
      margin-top: 20px;
      font-size: 16px;

      p {
        margin: 5px 0;

        a {
          color: white;
        }
      }
    }

    .contact-form {
      margin-top: 25px;
      z-index: 0;

      form {
        max-width: 625px;

        .form-inputs {
          display: flex;
          flex-direction: row;
          align-content: flex-end;

          @include breakpoint(lt-mobile) {
            flex-direction: column;
          }

          &:last-child {
            margin-bottom: 0px;
          }

          .form-column {
            flex: 0.5;
            display: flex;
            flex-direction: column;
            padding-right: 5px;

            @include breakpoint(lt-mobile) {
              flex: 1;
              margin-bottom: 10px;
            }

            input {
              font-family: "open sans", sans-serif;
              font-size: 14px;
              flex: 1;
              height: 30px;
              padding: 1px 5px;
              margin-bottom: 5px;
              color: #555555;
              border-radius: 1px;

              &:last-child {
                margin-bottom: 0px;
              }
            }

            textarea {
              font-family: "open sans", sans-serif;
              font-size: 14px;
              padding: 5px;
              height: 106px;
              resize: none;
              color: #555555;
            }
          }
        }

        .form-buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          flex: 1;
          margin-right: 5px;
          margin-top: 5px;

          .button {
            text-transform: uppercase;
            color: white;
            background-color: rgba(65, 183, 196, 1);
            border: none;
            height: 30px;
          }
        }
      }
    }
  }
}