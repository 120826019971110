
.PMap {
    .paragraph {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .map-icon {
            margin-right: 10px;
        }
    }

    .popover {
        background-color: rgba(0, 38, 72, 0.9);
        border-radius: 5px;
        padding: 10px;

        -webkit-box-shadow: 3px 3px 25px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 3px 3px 25px 0px rgba(0,0,0,0.75);
        box-shadow: 3px 3px 25px 0px rgba(0,0,0,0.75);

        iframe {
            position: relative;
            max-width: 100%;
            height: 350px;
        }
    }

    .rap-popover-content {
        animation-name: showMenu;
        animation-duration: 2s;



        polygon {
            transform: translateX(1px);
            fill: rgba(0, 38, 72, 0.9);
        }
    }
}

/* The animation code */
@keyframes showMenu {
    0% {
        opacity: 0;
    }
}
