@import "~styles/base";


/* Standard syntax */
@keyframes show-services {
  from {
    left: -100%;
  }

  to {
    left: 0%;
  }
}

@keyframes hide-services {
  from {
    left: 0%;
  }

  to {
    left: -100%;
  }
}

@keyframes hide-service {
  from {
    left: 0%;
  }

  to {
    left: 100%;
  }
}

@keyframes show-service {
  from {
    left: 100%;
  }

  to {
    left: 0%;
  }
}

.Services {
  display: flex;
  background-color: white;

  @include breakpoint(lt-mobile) {
    display: block;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px 0px;
    text-transform: uppercase;
    z-index: 1;

    @include breakpoint(gt-mobile) {
      min-width: $title-min-width;
      width: $title-width;
    }

    @include breakpoint(lt-mobile) {
      width: 100%;
    }

    font-size: 42px;
    color: rgb(0, 48, 91);

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;

      width: 100%;
      background-color: rgb(234, 244, 246);
      margin: auto;

      .title {
        margin-top: 50px;
        font-weight: bold;
      }

      @include breakpoint(gt-mobile) {
        align-items: flex-end;

        >* {
          margin-right: 40px;
        }
      }

      @include breakpoint(lt-mobile) {
        align-items: center;
        text-align: center;

        padding-bottom: 40px;
      }

      img {
        width: 80%;
        margin: 35px auto 0 -100%;

        transition-property: all;
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s;

        &.show {
          margin-left: 10%;
        }

        @include breakpoint(lt-mobile) {
          display: none;
        }
      }
    }
  }

  .content {
    flex: 1;
    background-color: rgb(250, 250, 250);
    position: relative;
    overflow: hidden;
    // padding: 90px 0;

    > * {
      // animation-name: show-service;
      // animation-duration: 1s;
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: 0.5s;
      width: 100%;
      background: white;
      height: fit-content;
      left: 100%;
      top: 0;
      position: absolute;
    }

    .service {
      overflow: hidden;
      padding-bottom: 10px;

      .header {
        display: flex;
        align-items: center;
        color: #00305b;
        text-transform: uppercase;

        button {
          background-color: transparent;
          border: transparent;
          margin-left: 10px;
          font-size: 30px;
          margin-right: 10px;
          outline: none;
          color: #00305b;
          cursor: pointer;

          &:hover {
            opacity: 0.5;
          }
        }
      }

      .body {
        color: #102e52;
        font-size: 18px;
        padding: 0 20px;

        li {
          margin-bottom: 10px;
        }
      }
    }

    >.hide {
      // animation-name: hide-service;
      // animation-duration: 1s;
      left: 200%;
    }

    >.show {
      // animation-name: show-service;
      // animation-duration: 1s;
      left: 0;
    }

    .default {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      transition-property: all;
      position: absolute;
      overflow: hidden;
      // animation-name: show-services;

      &.hide {
        margin-left: -100%;
        left: 0;
        margin-top: -200%;
        // animation-name: hide-services;
        // animation-duration: 1s;
      }

      &.show {
        left: 0;
        // animation-name: show-services;
        // animation-duration: 1s;
      }

      @include breakpoint(lt-mobile) {
        padding: 20px 0 90px 0;
        flex-direction: column;
      }

      > ::last-child {
        flex: 1;
      }
    }
  }
}