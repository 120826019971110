
/*********************
BREAKPOINTS
*********************/

$desktop-breakpoint: 70em;
$laptop-breakpoint: 64em;
$tablet-breakpoint: 50em;
$phablet-breakpoint: 37.5em;
$mobile-breakpoint: 37.5em;

@mixin breakpoint($point) {
         @if $point == gt-desktop {
        @media (min-width: $desktop-breakpoint) { @content ; }
    }
    @else if $point == gt-laptop {
        @media (min-width: $laptop-breakpoint) { @content ; }
    }
    @else if $point == gt-tablet {
        @media (min-width: $tablet-breakpoint) { @content ; }
    }
    @else if $point == gt-phablet {
        @media (min-width: $phablet-breakpoint)  { @content ; }
    }
    @else if $point == gt-mobile {
        @media (min-width: $mobile-breakpoint)  { @content ; }
    }
    @else if $point == lt-mobile {
        @media (max-width: $mobile-breakpoint)  { @content ; }
    }
    @else if $point == lt-phablet {
        @media (max-width: $phablet-breakpoint)  { @content ; }
    }
    @else if $point == lt-tablet {
        @media (max-width: $tablet-breakpoint)  { @content ; }
    }
    @else if $point == lt-labtop {
        @media (max-width: $laptop-breakpoint)  { @content ; }
    }
    @else if $point == lt-desktop {
        @media (max-width: $desktop-breakpoint)  { @content ; }
    }
}
